<template>
    <div class="wrapper">
        <div class="wrapper-in">
            <AppHeader></AppHeader>
            <main class="content" id="content-layout">
                <home></home>
            </main>
            <AppFooter></AppFooter>
            <formModal v-if="openFormModal"></formModal>
            <div class="body-overlay" v-if="openFormModal" @click="closeModal"></div>
        </div>
    </div>
</template>

<script>
    import AppHeader from '@/components/header/header.vue'
    import AppFooter from '@/components/footer/footer.vue'
    import home from '@/views/home.vue'
    import formModal from '@/components/modals/form-modal.vue';
    import {mapGetters} from 'vuex'
    export default {
        components: {
            AppHeader,
            AppFooter,
            home,
            formModal,
        },
        computed: mapGetters({
            openFormModal: 'loadOpenFormModal',
        }),
        methods: {
            closeModal() {
                this.$store.commit('Open_form_modal', false)
            }
        }
    }
</script>