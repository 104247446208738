<template>
    <div class="cards-section">
        <div class="container">
            <div class="section-title">
                <h2>{{section_title}}</h2>
                <a href="http://block.concrete.kz/price-list-concrete-block.pdf" download target="_blank" class="link-price">СКАЧАТЬ ПРАЙС ЛИСТ</a>
                <div class="phone">
                    <a :href="'tel:' + phone">
                        <svgIcon name="phone1"></svgIcon>
                        <p>{{phone}}</p>
                    </a>
                </div>
            </div>
            <div class="cards-items">
                <div class="item" v-for="(item, index) in cards" :key="index">
                    <div class="item-img">
                        <a href="#" class="btn-resize"><svgIcon name="resize"></svgIcon></a>
                        <img :src="item.img" alt="">
                    </div>
                    <div class="item-title">
                        <h3>{{item.title}}</h3>
                    </div>
                    <strong class="anounce">{{info.more}}</strong>
                    <div class="item-desc">
                        <ul>
                            <li>
                                <strong>{{info.size}}</strong>
                                <span>{{item.size}}</span>
                            </li>
                            <li>
                                <strong>{{info.volume}}</strong>
                                <span>{{item.volume}}</span>
                            </li>
                            <li>
                                <strong>{{info.density}}</strong>
                                <span>{{item.density}}</span>
                            </li>
                            <li>
                                <strong>{{info.quantity_m2}}</strong>
                                <span>{{item.quantity_m2}}</span>
                            </li>
                            <li>
                                <strong>{{info.quantity_m3}}</strong>
                                <span>{{item.quantity_m3}}</span>
                            </li>
                            <li>
                                <strong>{{info.weight}}</strong>
                                <span>{{item.weight}}</span>
                            </li>
                            <li>
                                <strong>{{info.pallet}}</strong>
                                <span>{{item.pallet}}</span>
                            </li>
                        </ul>
                    </div>
                    <a href="#" class="btn-call" @click.prevent="openForm">{{info.call}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import svgIcon from '@/components/partials/SvgIcon.vue';
    export default {
        name: 'cards-section',
        components: {
            svgIcon,
        },
        data () {
            return {
                section_title: 'Теплоблок неавтоклавного твердения',
                phone: '+7 727 311-83-46',
                info: {
                    more: 'Подробные хакактеристики данного теплоблока:',
                    call: 'ЗАКАЗАТЬ',
                    size: 'Размер мм',
                    volume: 'Объем м³',
                    density: 'Плотность',
                    quantity_m2: 'Кол-во м²',
                    quantity_m3: 'Кол-во м³',
                    weight: 'Вес 1 шт',
                    pallet: 'В поддоне',
                },
                cards: [
                    {
                        img: require('@/assets/images/samples/card1.png'),
                        title: 'Теплоблок Б-1',
                        size: '600х100х300',
                        volume: '0,018',
                        density: '600',
                        quantity_m2: '5,56',
                        quantity_m3: '55,6',
                        weight: '11,0 кг',
                        pallet: '48 шт',
                    },
                    {
                        img: require('@/assets/images/samples/card2.png'),
                        title: 'Теплоблок Б-1,5',
                        size: '600х150х300',
                        volume: '0,027',
                        density: '600',
                        quantity_m2: '5,56',
                        quantity_m3: '37,04',
                        weight: '16,5 кг',
                        pallet: '48 шт',
                    },
                    {
                        img: require('@/assets/images/samples/card3.png'),
                        title: 'Теплоблок Б-2',
                        size: '600х200х300',
                        volume: '0,036',
                        density: '600',
                        quantity_m2: '5,56',
                        quantity_m3: '27,8',
                        weight: '22,0 кг',
                        pallet: '32 шт',
                    },
                    {
                        img: require('@/assets/images/samples/card4.png'),
                        title: 'Теплоблок Б-2,5',
                        size: '600х250х300',
                        volume: '0,045',
                        density: '600',
                        quantity_m2: '5,56',
                        quantity_m3: '22,2',
                        weight: '27,5 кг',
                        pallet: '24 шт',
                    },
                    {
                        img: require('@/assets/images/samples/card5.png'),
                        title: 'Теплоблок Б-3',
                        size: '600х300х300',
                        volume: '0,054',
                        density: '600',
                        quantity_m2: '5,56',
                        quantity_m3: '18,5',
                        weight: '33,0 кг',
                        pallet: '18 шт',
                    },
                ],
            }
        },
        computed: mapGetters({
            openFormModal: 'loadOpenFormModal',
        }),
        methods: {
            openForm() {
                this.$store.commit('Open_form_modal', true);                
            }
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/sections/cards-section.scss';
    @import '@/styles/components/sections/respond/cards-section-respond.scss';
</style>