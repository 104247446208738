import { createStore } from 'vuex'

export default createStore({
  state: {
    isOpenFormModal: false,
  },
  getters: {
    loadOpenFormModal: state => state.isOpenFormModal,
  },
  actions: {
  },
  mutations: {
    Open_form_modal(state, data) {
        state.isOpenFormModal = data
    },
  },
  modules: {
  }
})
