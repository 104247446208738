<template>
    <div class="form-container">
        <div class="form-titles">
            <span>{{formTitle.title}}</span>
        </div>
        <Form class="form-contacts" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" v-if="formVisible">
            <div class="form-in">
                <TextInput
                    name="name"
                    type="text"
                    placeholder="Имя"
                    :class="{'error' : errors.name}"
                />
                <TextInput
                    name="phone"
                    type="text"
                    placeholder="+7 ("
                    v-maska="'+7 (###) ###-##-##'"
                    :class="{'error' : errors.phone}"
                />
                <TextInput
                    name="email"
                    type="text"
                    placeholder="Email"
                    :class="{'error' : errors.email}"
                />
                <div class="btn-send">
                    <button class="btn btn-submit btn-success" type="submit" :disabled="errors.name || errors.phone || errors.email">{{formTitle.btn}}</button>
                </div>
            </div>
            
        </Form>
        <div class="successMessage" v-if="!formVisible">
            <h3>Спасибо! Ваша заявка отправлена.</h3>
        </div>
    </div>
</template>

<script>
    import { Form } from "vee-validate";
    import * as Yup from "yup";
    import { ref } from 'vue'
    import TextInput from "@/components/forms/TextInput.vue";
    export default {
        name: 'FormFields',
        // props: ['titles'],
        data () {
            return {
                formTitle: {
                    title: 'Если у Вас возникли дополнительные вопросы, заполните форму и мы Вам перезвоним в ближайшее время:',
                    agree: 'Спасибо! Ваша заявка отправлена.',
                    btn: 'Отправить',
                    error: 'Заполните все поля',
                },
                formSet: {
                    name: 'Имя',
                    mail: 'E-mail',
                    phone: '+7 (',
                },
                phone: '',
                name: '',
                mail: '',
                agree: false,
                errorMsg: false,
                formVisible: true,
            }
        },
        components: {
            TextInput,
            Form,
        },
        setup() {
            const schema = Yup.object().shape({
                name: Yup.string()
                    .required('Title is required')
                    .matches(/^([а-яА-ЯёЁa-zA-Z].*[\s\.]*){3,35}$/),
                phone: Yup.string()
                    .required('First Name is required')
                    .matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10,10}$/),
                email: Yup.string()
                    .required('Last name is required')
                    .matches(/[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+/)
            });
            return {
                schema,
            };
        },
        methods: {
            onSubmit(values) {
                this.formVisible = false;
                console.log('ASDASDASDASDAS', values);
                axios.post('/formSend.php', JSON.stringify(values)).then(() => {
                    dataLayer.push({'event' : 'form_sent'})
                });
                $.ajax({
                    url: "https://docs.google.com/forms/d/e/1FAIpQLSeOcoBsV4CZQIqKQM2PWGTVWb8cK7CY9q0MwVAIeRLzYucJdw/formResponse",
                    data: {
                        // name
                        "entry.507989730": values.name,
                        // phone
                        "entry.1121400685": values.phone,
                        // email
                        "entry.178574183": values.email,
                    },
                    type: "POST",
                    dataType: "xml"
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/components/forms/form.scss';
    @import '@/styles/components/forms/form-respond.scss';
    // @import '@/styles/respond/forms/form-quality-respond.scss';
</style>
