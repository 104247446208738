<template>
    <div class="contacts-section">
        <div class="container">
            <div class="section-title">
                <h2>{{section_title}}</h2>
            </div>
            <div class="contacts-info">
                <div class="column-container">
                    <div class="col-left">
                        <h3>{{contacts_info.title}}</h3>
                        <div class="info">
                            <div class="left">
                                <div class="phones">
                                    <svgIcon name="phone1"></svgIcon>
                                    <ul>
                                        <li v-for="(item, index) in contacts_info.phones" :key="index"><a :href="'tel:' + item">{{item}}</a></li>
                                    </ul>
                                </div>
                                <div class="mobile">
                                    <svgIcon name="mobile"></svgIcon>
                                    <ul>
                                        <li v-for="(item, index) in contacts_info.mobile" :key="index"><a :href="'tel:' + item">{{item}}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="right">
                                <div class="emails">
                                    <svgIcon name="mail1"></svgIcon>
                                    <ul>
                                        <li v-for="(item, index) in contacts_info.emails" :key="index"><a :href="'mailto:' + item">{{item}}</a></li>
                                    </ul>
                                </div>
                                <div class="time">
                                    <svgIcon name="clock"></svgIcon>
                                    <div class="time-items">
                                        <div class="item" v-for="(item, index) in contacts_info.timework" :key="index">
                                            <span>{{item.days}}</span>
                                            <span>{{item.close}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-right">
                        <svgIcon name="call"></svgIcon>
                        <formStandart></formStandart>
                    </div>
                </div>
            </div>
            <div class="maps-container">
                <div class="map-col left-map">
                    <div class="map" id="yandexMapOne"></div>
                    <div class="map-info">
                        <strong>{{map_config_one.title}}</strong>
                        <span>{{map_config_one.adres}}</span>
                        <svgIcon name="arrow-down"></svgIcon>
                    </div>
                </div>
                <div class="map-col right-map">
                    <div class="map" id="yandexMapTwo"></div>
                    <div class="map-info">
                        <strong>{{map_config_two.title}}</strong>
                        <span>{{map_config_two.adres}}</span>
                        <svgIcon name="arrow-down"></svgIcon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import formStandart from '@/components/forms/form.vue'
    import { loadScript } from "vue-plugin-load-script";
    export default {
        name: 'contacts-section',
        components: {
            svgIcon,
            formStandart,
        },
        data () {
            return {
                section_title: 'Контакты и адреса',
                map_marker: require('@/assets/images/map-marker.svg'),
                zoom: 16,
                contacts_info: {
                    title: 'По вопросам покупки, сотрудничества и прочим запросам Вы можно связаться с нашим отделом продаж используя контакты ниже:',
                    phones: [
                        '+7 727 311-83-46',
                        '+7 727 311-83-49',
                        '+7 727 311-83-60'
                    ],
                    mobile: [
                        '+7 700 978-84-36',
                        '+7 700 978-84-37',
                        '+7 700 978-84-39'
                    ],
                    emails: [
                        'sales@concrete.kz',
                        'kp.marketing@mail.ru',
                    ],
                    timework: [
                        {
                            days: 'ПН-ПТ с 08:00 – 17:00,',
                            close: 'перерыв с 12:00 до 13:00',
                        },
                        {
                            days: 'СБ с 8:00 – 14:00,',
                            close: 'без перерыва',
                        }
                    ]
                },
                // map_config_one: {
                //     title: 'Консультация и выписка документов ',
                //     adres: 'ул. Сатпаева 90/5',
                //     lat: 43.232808,
                //     lng: 76.879196,
                // },
                map_config_one: {
                    title: 'Консультация и выписка документов ',
                    adres: 'ул. Ангарская 129',
                    lat: 43.309327,
                    lng: 76.906029,
                },
                map_config_two: {
                    title: 'Получение продукции',
                    adres: 'ул. Ангарская 129',
                    lat: 43.309327,
                    lng: 76.906029,
                }
            }
        },
        methods: {
            initMap() {
                const _self = this;
                loadScript("https://api-maps.yandex.ru/2.1.75/?apikey=96ae7f37-08b5-44d2-98f3-f30ff1ada250&lang=ru_RU")
                .then(() => {
                    var marker = _self.map_marker;
                    var zoom = _self.zoom;
                    var YmapOne;
                    var YmaTwo;
                    var myPlacemarkOne;
                    var myPlacemarkTwo;
                    var ymaps = window.ymaps;
                    ymaps.ready().then(function() {

                        var initMap_one = function() {
                            YmapOne = new ymaps.Map('yandexMapOne', {
                                center: [_self.map_config_one.lat, _self.map_config_one.lng],
                                zoom: zoom,
                                controls: ['fullscreenControl'],
                                behaviors: ["drag", "rightMouseButtonMagnifier", "multiTouch"]
                            },{suppressMapOpenBlock: true, minZoom: 3, maxZoom: 20});
                            YmapOne.controls.add('zoomControl', {position: {left: '10px', top: '180px'}});
                            var buildMainPin = function() {
                                var lat = _self.map_config_one.lat,
                                    lng = _self.map_config_one.lng;
                                myPlacemarkOne = new ymaps.Placemark([lat, lng], {}, {
                                    iconLayout: 'default#image',
                                    iconImageHref: marker,
                                    iconImageSize: [148, 66],
                                    iconImageOffset: [-20, -50]
                                });
                                YmapOne.geoObjects.add(myPlacemarkOne);
                            };
                            buildMainPin()
                        };

                        var initMap_Two = function() {
                            YmaTwo = new ymaps.Map('yandexMapTwo', {
                                center: [_self.map_config_two.lat, _self.map_config_two.lng],
                                zoom: zoom,
                                controls: ['fullscreenControl'],
                                behaviors: ["drag", "rightMouseButtonMagnifier", "multiTouch"]
                            },{suppressMapOpenBlock: true, minZoom: 3, maxZoom: 20});
                            YmaTwo.controls.add('zoomControl', {position: {right: '10px', top: '180px'}});
                            var buildMainPin = function() {
                                var lat = _self.map_config_two.lat,
                                    lng = _self.map_config_two.lng;
                                myPlacemarkTwo = new ymaps.Placemark([lat, lng], {}, {
                                    iconLayout: 'default#image',
                                    iconImageHref: marker,
                                    iconImageSize: [148, 66],
                                    iconImageOffset: [-20, -50]
                                });
                                YmaTwo.geoObjects.add(myPlacemarkTwo);
                            };
                            buildMainPin()
                        };
                        
                        initMap_one();
                        initMap_Two();
                    })
                })
                .catch(() => {
                // Failed to fetch script
                });
            },
        },
        created () {
            this.initMap();
        },
        mounted () {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/sections/contacts-section.scss';
    @import '@/styles/components/sections/respond/contacts-section-respond.scss';
</style>