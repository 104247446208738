<template>
    <header class="header" id="header">
        <div class="container">
            <div class="columns">
                <div class="col">
                    <div class="logo-project">
                        <a href="#">
                            <img alt="concrete block logo" :src="logo">
                        </a>
                    </div>
                    <div class="years" v-if="!isMobile">
                        <img :src="logo_years" alt="">
                        <strong>{{title}}</strong>
                    </div>
                    <div class="logo-cp logo-cp-mobile" v-if="isMobile">
                        <a href="https://concrete.kz/" target="_blank">
                            <img :src="logo_cp" alt="">
                        </a>
                    </div>
                </div>
                <div class="col">
                    <div class="years years-mobile" v-if="isMobile">
                        <img :src="logo_years" alt="">
                        <strong>{{title}}</strong>
                    </div>
                    <div class="call-info">
                        <div class="phone">
                            <a :href="'tel:' + phone">
                                <svgIcon name="phone1"></svgIcon>
                                <p>{{phone}}</p>
                            </a>
                        </div>
                        <div class="mail">
                            <a :href="'mailto:' + mail">
                                <svgIcon name="mail1"></svgIcon>
                                <p>{{mail}}</p>
                            </a>
                        </div>
                    </div>
                    <div class="logo-cp" v-if="!isMobile">
                        <a href="https://concrete.kz/" target="_blank">
                            <img :src="logo_cp" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    // import {mapGetters} from 'vuex'
    // import navMain from '@/components/nav-main/nav-main.vue'
    import svgIcon from '@/components/partials/SvgIcon.vue';
    export default {
        name: 'AppHeader',
        components: {
            // navMain,
            svgIcon,
        },
        data () {
            return {
                logo: require('@/assets/images/logo.svg'),
                logo_cp: require('@/assets/images/cp-logo.svg'),
                logo_years: require('@/assets/images/30years.svg'),
                title: 'в производстве бетонных изделий',
                mail: 'sales@concrete.kz',
                phone: '+7 700 978-84-35',
                isMobile: false,
            }
        },
        // computed: mapGetters({
        //     openSendwich: 'loadOpenSendwich',
        // }),
        methods: {
            isMobileCheck() {
                if (window.innerWidth < 767) {
                    this.isMobile = true
                }
            },
            handleResize() {
                if (window.innerWidth < 767) {
                    this.isMobile = true
                } else {
                    this.isMobile = false
                }
            },
            checkResize() {
                window.addEventListener('resize', this.handleResize)
                this.handleResize();
            },
        },
        beforeCreate() {
        },
        created () {
        },
        mounted () {
            this.isMobileCheck();
            this.checkResize();
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/header/header.scss';
    @import '@/styles/components/header/header-respond.scss';
    // @import '@/styles/respond/header/respond-header.scss';
</style>