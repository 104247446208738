<template>
    <div class="used-section">
        <div class="container">
            <div class="section-title">
                <h2>{{section_title}}</h2>
            </div>
            <div class="column-container">
                <div class="col-left">
                    <div class="cards-items">
                        <div class="item" v-for="(item, index) in cards" :key="index">
                            <div class="desc">
                                <img :src="checkbox" alt="">
                                <p>{{item.title}}</p>
                            </div>
                            <div class="item-img">
                                <img :src="item.img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-right">
                    <h3>{{title}}</h3>
                    <div class="list-items">
                        <ul class="left-list">
                            <li v-for="(item, index) in list.slice(0, 4)" :key="index">
                                <img :src="item.icon" alt="">
                                <span>{{item.title}}</span>
                            </li>
                        </ul>
                        <ul class="right-list">
                            <li v-for="(item, index) in list.slice(4)" :key="index">
                                <img :src="item.icon" alt="">
                                <span>{{item.title}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'used-section',
        components: {
        },
        data () {
            return {
                section_title: 'Где используют теплоблоки при строительстве',
                title: 'Теплоблок обладает высокими эксплуатационными качествами:',
                checkbox: require('@/assets/images/checkbox.svg'),
                cards: [
                    {
                        title: 'Здания различной этажности',
                        img: require('@/assets/images/samples/index-pic01.png'),
                    },
                    {
                        title: 'Как наружные не несущие стены и перегородки',
                        img: require('@/assets/images/samples/index-pic02.png'),
                    },
                ],
                list: [
                    {
                        title: 'Теплоизоляционный',
                        icon:  require('@/assets/images/used-icon01.svg'),
                    },
                    {
                        title: 'Энергосберегающий',
                        icon:  require('@/assets/images/used-icon02.svg'),
                    },
                    {
                        title: 'Звуко и шумоизоляционный',
                        icon:  require('@/assets/images/used-icon03.svg'),
                    },
                    {
                        title: 'Малый вес',
                        icon:  require('@/assets/images/used-icon04.svg'),
                    },
                    {
                        title: 'Огнестойкий',
                        icon:  require('@/assets/images/used-icon05.svg'),
                    },
                    {
                        title: 'Морозостойкий',
                        icon:  require('@/assets/images/used-icon06.svg'),
                    },
                    {
                        title: 'Выдерживает климатические условия',
                        icon:  require('@/assets/images/used-icon07.svg'),
                    },
                    {
                        title: 'Экологичный',
                        icon:  require('@/assets/images/used-icon08.svg'),
                    },
                ],
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/sections/used-section.scss';
    @import '@/styles/components/sections/respond/used-section-respond.scss';
</style>