<template>
    <div class="about-section">
        <div class="container container-about">
            <div class="section-title">
                <h2>{{section_title}}</h2>
            </div>
            <div class="about-cards">
                <div class="card" v-for="(item, index) in  cards" :key="index">
                    <div class="card-desc">
                        <strong>{{item.bold}}</strong>
                        <p>{{item.thin}}</p>
                    </div>
                    <div class="card-img">
                        <div class="in">
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-collapse-items">
                <div class="column-container">
                    <div class="col-left">
                        <div class="item" v-for="(item, index) in desc_items.slice(0, 3)" :key="index" :id="'collapse_' + index">
                            <div class="item-header">
                                <div class="item-check">
                                    <img :src="checkbox" alt="">
                                </div>
                                <div class="item-title">
                                    <h3>{{item.title}}</h3>
                                </div>
                                <div class="item-btn-resize">
                                    <a href="#" @click.prevent="toggleCollapse('collapse_' + index, index, $event)">
                                        <span class="resize">
                                            <svgIcon name="arrow-resize"></svgIcon>
                                            <p>{{info.resize}}</p>
                                        </span>
                                        <span class="unresize">
                                            <svgIcon name="arrow-unresize"></svgIcon>
                                            <p>{{info.unsize}}</p>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div class="item-desc">
                                <p v-for="(paragraf, index) in item.desc" :key="index">{{paragraf}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-right">
                        <div class="item" v-for="(item, index) in desc_items.slice(3)" :key="index" :id="'collapse_' + parseInt(index + 3)">
                            <div class="item-header">
                                <div class="item-check">
                                    <img :src="checkbox" alt="">
                                </div>
                                <div class="item-title">
                                    <h3>{{item.title}}</h3>
                                </div>
                                <div class="item-btn-resize">
                                    <a href="#" @click.prevent="toggleCollapse('collapse_' + parseInt(index + 3), index, $event)">
                                        <span class="resize">
                                            <svgIcon name="arrow-resize"></svgIcon>
                                            <p>{{info.resize}}</p>
                                        </span>
                                        <span class="unresize">
                                            <svgIcon name="arrow-unresize"></svgIcon>
                                            <p>{{info.unsize}}</p>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div class="item-desc">
                                <p v-for="(paragraf, index) in item.desc" :key="index">{{paragraf}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
         <documentsSection></documentsSection>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import documentsSection from '@/components/sections/documents-section.vue'
    export default {
        name: 'about-section',
        components: {
            svgIcon,
            documentsSection,
        },
        data () {
            return {
                section_title: 'Описание и технические характеристики ',
                checkbox: require('@/assets/images/checkbox.svg'),
                info: {
                    resize: 'Развернуть',
                    unsize: 'Свернуть'
                },
                cards: [
                    {
                        img: require('@/assets/images/samples/index-pic03.png'),
                        bold: 'Долговечность ячеистых бетонов проверена многолетней практикой эксплуатации многочисленных объектов в Республики Казахстан и за её пределами.',
                        thin: 'В современных условиях, когда требования к теплозащитным свойствам ограждающих конструкций зданий повышены более, чем в 3 раза, одним из немногих строительных материалов, пригодных для возведения однослойных наружных стен приемлемой толщины (менее 50 см), является ячеистый бетон.',
                    },
                    {
                        img: require('@/assets/images/samples/index-pic04.png'),
                        bold: 'Задумываясь о долговечности будущего строения, необходимо отдавать предпочтение промышленной продукции, выпускаемой в соответствии с требованиями действующих нормативных документов.',
                        thin: 'Газобетонные блоки широко используются в строительстве как жилых зданий различной этажности, так и для гаражей, сараев и других технических помещений. Блоки из неавтоклавного газобетона можно применять для строительства в любых климатических районах страны.'
                            + 'При возведении многоэтажных зданий газобетонные блоки используют в качестве наружных ненесущих стен и перегородок.',
                    },
                ],
                desc_items: [
                    {
                        title: 'Энерогосбережение',
                        desc: [
                            'Благодаря внутреннему строению бетона – воздушным ячейкам, неавтоклавный газобетон хорошо сохраняет тепло.',
                            'Энергосберегающие свойства газоблоков позволяют круглогодично поддерживать в помещении благоприятную температуру и уменьшить объем расходов на обогрев.',
                            'Газоблоки укладывают не на цементный раствор, а на специальный клеевой состав, с толщиной швов 2-5 мм, что улучшает общие теплоизоляционные свойства стены.',
                        ]
                    },
                   
                    {
                        title: 'Повышенная шумоизоляция',
                        desc: [
                            'Это обеспечивает комфортные условия для проживания в зданиях построенных с ипользованием газоблоков.',
                            'Эффективная шумоизоляция стен из газобетона обеспечивается благодаря насыщению массива воздушными порами. Пористая структура гасит звук при прохождении сквозь нее.',
                        ]
                    },
                    {
                        title: 'Небольшой вес',
                        desc: [
                            'Удобный для работы не большой вес и удобная геометрия, а также широкая номенклатура размеров - толщиной от 100 до 300 мм. Газоблоки для кладки подаются вручную, а газобетонные стены не оказывают повышенной нагрузки на фундаментную основу.',
                        ]
                    },
                    {
                        title: 'Пожароустойчивость',
                        desc: [
                            'Неавтоклавный газобетон относится к классу негорючих веществ – изготавливается из природного минерального негорючего сырья – цемента и песка.',
                            'При нагреве материала не выделяются токсичные вещества. Негорючесть и высокая пожароустойчивость являются добавочным преимуществом ячеистых бетонов против огня.'
                          + 'Стены из неавтоклавного газобетона сохраняют несущую способность, при одностороннем воздействии огня, в течение не менее трёх-четырёх часов.',
                        ]
                    },
                    {
                        title: 'Удобство работы',
                        desc: [
                            'Газобетонные блоки прекрасно обрабатываются на месте механическими способами: фрезеровка, шлифовка, штробление, сверление и распиливание.',
                            'Эти характеристики позволяют выполнять строительно-монтажные работы без использования дорогостоящих средств механизации.',
                            'Кроме того следует предусмотреть облицовку газобетона, где можно использовать штукатурку, вентилируемый фасад или обложить кирпичом.',
                            'Дом из газоблоков, в отличие от деревянного, не подвержен воздействию грибка, не является пищей для грызунов и насекомых.'
                        ]
                    },
                ]
            }
        },
        methods: {
            toggleCollapse(id) {
                document.getElementById(id).classList.toggle("collapse_in");
            },
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/sections/about-section.scss';
    @import '@/styles/components/sections/respond/about-section-respond.scss';
</style>