<template>
    <div class="documents-section">
        <div class="container">
            <div class="section-title">
                <h2>{{section_title}}</h2>
            </div>
            <div class="files-items">
                <div class="item" v-for="(item, index) in files_items" :key="index">
                    <h3>{{item.title}}</h3>
                    <a :href="item.file" download target="_blank">
                        <svgIcon name="download"></svgIcon>
                        <span>{{btn_download}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    export default {
        name: 'documents-section',
        components: {
            svgIcon,
        },
        data () {
            return {
                section_title: 'Документация',
                checkbox: require('@/assets/images/checkbox.svg'),
                btn_download: 'Скачать файл',
                files_items: [
                    {
                        title: 'Техническая характеристика неавтоклавного теплоблока',
                        file: 'http://block.concrete.kz/docs/doc_01.pdf',
                    },
                    {
                        title: 'Сертификат о происхождении товара',
                        file: 'http://block.concrete.kz/docs/doc_02.pdf',
                    },
                    {
                        title: 'Сертификат соответствия',
                        file: 'http://block.concrete.kz/docs/doc_03.pdf',
                    },
                ]
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/sections/documents-section.scss';
    @import '@/styles/components/sections/respond/documents-section-respond.scss';
</style>