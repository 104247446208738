<template>
    <div class="project-section">
        <div class="container">
            <div class="section-title">
                <h2>{{section_title}}</h2>
                <div class="years">
                    <img :src="logo_years" alt="">
                    <strong>{{title}}</strong>
                </div>
            </div>
            <div class="project-carousel">
                <swiper 
                    :modules="modules" 
                    :navigation="swiperOptions.navigation"
                    :slidesPerView="3"
                    :spaceBetween="50"
                    :observer="true"
                    :observeParents="true"
                    :loop="true"
                    :breakpoints="swiperOptions.breakpoints"
                >
                    <swiper-slide v-for="(item, index) in project_items" :key="index">
                        <div class="slide-content">
                            <div class="slide-pic">
                                <img :src="item.img" alt="">
                            </div>
                            <strong>{{item.name}}</strong>
                            <h3>{{item.title}}</h3>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-controls project-controls">
                    <div class="project-prev-slide slider-btn" id="projectPref"><svgIcon name="arrow-left1"></svgIcon></div>
                    <div class="project-next-slide slider-btn" id="projectNext"><svgIcon name="arrow-right1"></svgIcon></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import { EffectFade, Autoplay, Navigation} from 'swiper';
    import { Swiper, SwiperSlide} from 'swiper/vue';
    import 'swiper/scss/effect-fade';
    import 'swiper/scss/autoplay';
    import 'swiper/scss';
    export default {
        name: 'project-section',
        components: {
            Swiper,
            SwiperSlide,
            svgIcon,
        },
        data () {
            return {
                swiperOptions: {
                    navigation: {
                        nextEl: '#projectNext',
                        prevEl: '#projectPref'
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: false
                    },
                    breakpoints: {
						0: {
							slidesPerView: 1,
							spaceBetween: 10
						},
						812: {
							slidesPerView: 2,
							spaceBetween: 10
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 50
						}
					}
                },
                section_title: 'Наши проекты',
                logo_years: require('@/assets/images/30years.svg'),
                title: 'в производстве бетонных изделий',
                project_items: [
                    {
                        name: 'Жилой Комплекс',
                        title: 'metropole',
                        img: require('@/assets/images/samples/index-pic05.png'),
                    },
                    {
                        name: 'Жилой Комплекс',
                        title: 'Ауэзов сити',
                        img: require('@/assets/images/samples/index-pic06.png'),
                    },
                    {
                        name: 'Жилой Комплекс',
                        title: 'RIVIERA',
                        img: require('@/assets/images/samples/index-pic07.png'),
                    },
                    {
                        name: 'Жилой Комплекс',
                        title: 'metropole',
                        img: require('@/assets/images/samples/index-pic05.png'),
                    },
                    {
                        name: 'Жилой Комплекс',
                        title: 'Ауэзов сити',
                        img: require('@/assets/images/samples/index-pic06.png'),
                    },
                    {
                        name: 'Жилой Комплекс',
                        title: 'RIVIERA',
                        img: require('@/assets/images/samples/index-pic07.png'),
                    },
                ]
            }
        },
        setup() {
            return {
                EffectFade,
                modules: [Navigation, Autoplay, EffectFade],
            };
        },  
    }
</script>

<style lang="scss">
    @import '@/styles/components/sections/project-section.scss';
    @import '@/styles/components/sections/respond/project-section-respond.scss';
</style>