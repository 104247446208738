<template>
    <div class="home-page">
        <promoSection></promoSection>
        <usedSection></usedSection>
        <cardsSection></cardsSection>
        <aboutSection></aboutSection>
        <projectSection></projectSection>
        <contactsSection></contactsSection>
    </div>
</template>

<script>
    import promoSection from '@/components/sections/promo-section.vue'
    import usedSection from '@/components/sections/used-section.vue'
    import cardsSection from '@/components/sections/cards-section.vue'
    import aboutSection from '@/components/sections/about-section.vue'
    import projectSection from '@/components/sections/project-section.vue'
    import contactsSection from '@/components/sections/contacts-section.vue'

    export default {
        name: 'HomeView',
        components: {
            promoSection,
            usedSection,
            cardsSection,
            aboutSection,
            projectSection,
            contactsSection,
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/home-page/home.scss';
</style>