<template>
    <div class="promo-section">
        <div class="container">
            <div class="promo-carousel">
                <swiper 
                    :modules="modules" 
                    :navigation="swiperOptions.navigation"
                    effect="fade"
                    :slidesPerView="1"
                    :spaceBetween="0"
                    :observer="true"
                    :observeParents="true"
                >
                    <swiper-slide v-for="(item, index) in cards" :key="index">
                        <div class="slide-content">
                            <div class="col-left">
                                <h2>{{item.title}}</h2>
                                <strong>{{item.name}}</strong>
                                <div class="check">
                                    <img :src="checkbox" alt="">
                                    <span>{{item.desc}}</span>
                                </div>
                                <!-- <div class="sale">СКИДКА 10%</div> -->
                            </div>
                            <div class="col-right">
                                <div class="slide-pic">
                                    <img :src="item.img" alt="">
                                </div>
                            </div>
                        </div>
                       
                    </swiper-slide>
                </swiper>
                <div class="swiper-controls promo-controls">
                    <div class="promo-prev-slide slider-btn" id="promoPref"><svgIcon name="arrow-left1"></svgIcon></div>
                    <div class="promo-next-slide slider-btn" id="promoNext"><svgIcon name="arrow-right1"></svgIcon></div>
                </div>
                <a href="#" class="link-form" @click.prevent="openForm">Оставить заявку</a>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    // :autoplay="autoplay"
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import { EffectFade, Autoplay, Navigation} from 'swiper';
    import { Swiper, SwiperSlide} from 'swiper/vue';
    import 'swiper/scss/effect-fade';
    import 'swiper/scss/autoplay';
    import 'swiper/scss';
    export default {
        name: 'promo-section',
        components: {
            Swiper,
            SwiperSlide,
            svgIcon,
        },
        data () {
            return {
                swiperOptions: {
                    navigation: {
                        nextEl: '#promoNext',
                        prevEl: '#promoPref'
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: false
                    },
                },
                checkbox: require('@/assets/images/checkbox.svg'),
                cards: [
                    {
                        title: 'Неавтоклавный газобетон',
                        name: 'ТЕПЛОБЛОК Б1 — 600х100х300 мм',
                        desc: 'Относятся к ячеистым бетонам',
                        img:  require('@/assets/images/samples/b1_wall.png'),
                    },
                    {
                        title: 'Неавтоклавный газобетон',
                        name: 'ТЕПЛОБЛОК Б1,5 — 600х150х300 мм',
                        desc: 'Экологический  чистый продукт',
                        img:  require('@/assets/images/samples/b1.5_wall.png'),
                    },
                    {
                        title: 'Неавтоклавный газобетон',
                        name: 'ТЕПЛОБЛОК Б2 — 600х200х300 мм',
                        desc: 'Имеет пористую структуру',
                        img:  require('@/assets/images/samples/b2_wall.png'),
                    },
                    {
                        title: 'Неавтоклавный газобетон',
                        name: 'ТЕПЛОБЛОК Б2,5 — 600х250х300 мм',
                        desc: 'Обладает выгодными преимуществами',
                        img:  require('@/assets/images/samples/b2.5_wall.png'),
                    },
                    {
                        title: 'Неавтоклавный газобетон',
                        name: 'ТЕПЛОБЛОК Б3 — 600х300х300 мм',
                        desc: 'Используется в современном строительстве',
                        img:  require('@/assets/images/samples/b3_wall.png'),
                    },
                ],
            }
        },
        setup() {
            return {
                EffectFade,
                modules: [Navigation, Autoplay, EffectFade],
            };
        },  
        methods: {
            openForm() {
                this.$store.commit('Open_form_modal', true)
            }
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/sections/promo-section.scss';
    @import '@/styles/components/sections/respond/promo-section-respond.scss';
</style>