import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import LoadScript from "vue-plugin-load-script";
import Maska from 'maska'

window.axios = require('axios');
window.$ = window.jQuery = require('jquery');

const app = createApp(App).use(store);
app.use(LoadScript);
app.use(Maska);
app.mount('#app');
