<template>
    <div class="form-modal">
        <div class="phone">
            <a :href="'tel:' + phone">
                <svgIcon name="phone1"></svgIcon>
                <p>{{phone}}</p>
            </a>
        </div>
        <a href="#" class="close-form" @click.prevent="closeModal"><svgIcon name="close"></svgIcon></a>
        <formStandart class="form-modal-standart"></formStandart>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import formStandart from '@/components/forms/form.vue'
    // import TextInput from "@/components/forms/TextInput.vue";
    export default {
        name: 'FormFields',
        // props: ['titles'],
        data () {
            return {
                phone: '+7 727 311-83-46',
            }
        },
        computed: mapGetters({
            openFormModal: 'loadOpenFormModal',
        }),
        components: {
            svgIcon,
            formStandart,
            // TextInput,
        },
        methods: {
            closeModal() {
                this.$store.commit('Open_form_modal', false)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/components/modals/form-modal.scss';
    // @import '@/styles/components/forms/form-respond.scss';
</style>
